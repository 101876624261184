module.exports = {
  'selectLanguage': 'Select your language',
  'tags': 'Tags',
  'tags.allTagsLink': 'Browse all tags',
  'tags.intro': 'Here you can find all the tags',
  'tags.blog.intro': 'Here you can find all the blog tags',
  'tags.pages.intro': 'Here you can find all the tags in the pages',
  'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
      one {post}
      other {posts}
    } tagged with`,
  'artworks': 'Artworks',
  'introduction': 'Introduction',
  'portfolio': 'Portfolio',
  'painting': 'Painting',
  'painting-new': '2019',
  'painting-oldest': 'oldest',
  'sculpture': 'Sculpture',
  'marble': 'Marble',
  'wood': 'Wood',
  'bronze': 'Bronze',
  'other-materials': 'Other materials',
  'performance': 'Performance',
  'performance01': 'Performance 01',
  'performance02': 'Performance 02',
  'performance03': 'Performance 03',
  'performance04': 'Performance 04',
  'new-media': 'New media',
  'augmented_reality': 'Augmented reality',
  'interactivity': 'Interactivity',
  'experimental': 'Experimental',
  'blog-message': 'Last News',
  'keep-reading': 'Keep reading →',
  'find-out-more': 'Find out more...',
  'followus': 'Follow Us: ',
  'info-sheet': 'Informative sheet',
  'info-sheet-title': 'Title: ',
  'info-sheet-year': 'Year: ',
  'info-sheet-technique': 'Technique: ',
  'info-sheet-dimensions': 'Dimensions: ',
  'contact.gender.male': 'Male',
  'contact.gender.female': 'Female',
  'contact.enquiry': 'Type of Enquiry',
  'contact.enquiry.a': 'Need to know more',
  'contact.enquiry.b': 'About art',
  'contact.enquiry.c': 'Want to say hello',
  'menu-icon-message': "Choose a section",
  'menu-icon-subtitle': "Artworks sections",
  'menu-icon-painting': "Painting",
  'menu-icon-sculpture': "Sculpture",
  'menu-icon-performance': "Performance",
  'menu-icon-interactivity': "Interactivity",
  'home': 'Home',
  'isco08': 'ISCO-08',
  'faq': "FAQ",
  'resource': "Tools & Resources",
  'archive': "Previous Versions",
  'blog': 'News',
  'contact': 'Support',
  'contact.infos': 'Contact informations',
  'contact.fill-the-form': 'Fill the form to send a message',
  'contact.name': 'Name',
  'contact.surname': 'Surname',
  'contact.email': 'Email',
  'contact.subject': 'Subject',
  'contact.message': 'Message',
  'contact.send': 'Send',
  'not_found': "404 - Page Not Found",
  '404': "Sorry, you have arrived at an unvailable page or you have misspelled the path?",
  'copyright': "International Labour Organization (ILO)",
};
