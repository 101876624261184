import React from 'react'
import { FormattedMessage } from 'react-intl';
import { FaCopyright } from 'react-icons/fa';

const Copyright = () => (
   <section className="section">
      <div className="container content is-fluid has-text-light">
        <FaCopyright className="menu-names" /> {new Date().getFullYear()} <FormattedMessage id="copyright"/>
      </div>
   </section>
)

export default Copyright
